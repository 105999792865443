/* ProjectModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-container {
    position: relative;
    background: white;
    width: 90%;
    max-width: 1200px;
    max-height: 90vh;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    overflow: hidden;
  }
  
  .modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1002;
    padding: 8px;
    color: #666;
    transition: color 0.3s ease;
  }
  
  .modal-close:hover {
    color: #333;
  }
  
  .modal-content {
    display: flex;
    height: 100%;
  }
  
  .slideshow-container {
    position: relative;
    width: 66.66%;
    height: 600px;
    background-color: #1a1a1a;
  }
  
  .slideshow-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgb(0 0 0 / 52%);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    
  }
  
  .nav-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  .nav-button.prev {
    left: 1rem;
  }
  
  .nav-button.next {
    right: 1rem;
  }
  
  .nav-icon {
    color: white;
    width: 24px;
    height: 24px;
  }
  
  .image-counter {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 14px;
  }
  
  .content-section {
    width: 33.33%;
    padding: 2rem;
    overflow-y: auto;
    max-height: 600px;
  }
  
  .project-title {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 0.5rem 0;
    color: #333;
  }
  
  .project-location {
    font-size: 16px;
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  .project-description,
  .project-details {
    margin-bottom: 2rem;
    white-space: pre-wrap;
  }
  
  .section-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .details-list {
    list-style-type: disc;
    padding-left: 1.5rem;
    color: #444;
  }
  
  .details-list li {
    margin-bottom: 0.5rem;
  }
  
  /* Prevent body scroll when modal is open */
  .prevent-scroll {
    overflow: hidden;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .modal-content {
      flex-direction: column;
    }
  
    .slideshow-container {
      width: 100%;
      height: 300px;
    }
  
    .content-section {
      width: 100%;
      max-height: 300px;
      padding: 1.5rem;
    }
  
    .project-title {
      font-size: 20px;
    }
  
    .section-title {
      font-size: 16px;
    }
  
    .nav-button {
      width: 36px;
      height: 36px;
    }
  
    .nav-icon {
      width: 20px;
      height: 20px;
    }
  }
  /* Add these new styles to your existing ProjectModal.css */

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-wrapper.loading {
  background-color: #f5f5f5;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #000000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.slideshow-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.nav-button:disabled {
  opacity: 4.5;
  cursor: not-allowed;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add these media queries for better mobile support */
@media (max-width: 768px) {
  .modal-container {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
  }

  .slideshow-container {
    height: 50vh;
  }

  .nav-button {
    padding: 8px;
  }

  .modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .content-section {
    flex: 1;
    overflow-y: auto;
  }
}