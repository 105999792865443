@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "icomoon";
  src: url("./fonts/icomoon/icomoon.eot");
  src: url("./fonts/icomoon/icomoon.eot") format("embedded-opentype"),
    url("./fonts/icomoon/icomoon.ttf") format("truetype"),
    url("./fonts/icomoon/icomoon.woff") format("woff"),
    url("./fonts/icomoon/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* =======================================================
  *
  * 	Template Style 
  *
  * ======================================================= */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Shippori Antique";
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7;
  color: #767676;
  background: rgba(232, 232, 232, 0.6);
}
body:before,
body:after {
  content: "";
  position: fixed;
  background: #fff;
  height: 20px;
  z-index: 100;
}
@media screen and (max-width: 768px) {
  body:before,
  body:after {
    display: none;
  }
}
body:before {
  top: 0;
}
body:after {
  bottom: 0;
}
@media screen and (max-width: 768px) {
  body {
    border-left: none;
    border-right: none;
  }
}

#root {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.offcanvas #root {
  overflow: hidden;
  position: absolute;
}
.offcanvas #root:after {
  -webkit-transition: 2s;
  -o-transition: 2s;
  transition: 2s;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.7);
  content: "";
}

a {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
a:hover,
a:active,
a:focus {
  color: #f95959;
  outline: none;
  text-decoration: none;
}

p {
  margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
figure {
  color: #000000;;
  font-family: "Shippori Antique";
  font-weight: 500;
  margin: 0 0 20px 0;
}

::-webkit-selection {
  color: #fff;
  background: #f95959;
}

::-moz-selection {
  color: #fff;
  background: #f95959;
}

::selection {
  color: #fff;
  background: #f95959;
}

.container-wrap {
  width: 1300px;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .container-wrap {
    width: 100%;
    padding: 0 1em;
  }
}

.fh5co-nav {
  margin: 4em 0 2em 0;
}
@media screen and (max-width: 768px) {
  .fh5co-nav {
    margin: 2em 0 2em 0;
  }
}
.fh5co-nav .top-menu {
  padding: 0;
}
.fh5co-nav #fh5co-logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-family: "Shippori Antique";
}
.menu-1 a:hover,
.menu-1 a.active {
  color: #000000;
}
.fh5co-nav #fh5co-logo a {
  margin-bottom: 0;
  padding: 0;
  color: #000;
  display: block;
  line-height: 32px;
}
@media screen and (max-width: 768px) {
  .fh5co-nav .menu-1 {
    display: none;
  }
}
.fh5co-nav ul {
  padding: 0;
  margin: 1em 0 0 0;
}
.fh5co-nav ul li {
  font-family: "Shippori Antique";
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline;
  font-size: 18px;
}
.fh5co-nav ul li a {
  position: relative;
  padding: 30px 15px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  color: #999999;
}
.fh5co-nav ul li.has-dropdown {
  position: relative;
}
.fh5co-nav ul li.has-dropdown .dropdown {
  width: 140px;
  -webkit-box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  z-index: 1002;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 30px;
  left: 0;
  text-align: left;
  background: #000;
  padding: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}
.fh5co-nav ul li.has-dropdown .dropdown:before {
  bottom: 100%;
  left: 40px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #000;
  border-width: 8px;
  margin-left: -8px;
}
.fh5co-nav ul li.has-dropdown .dropdown li {
  display: block;
  margin-bottom: 7px;
}
.fh5co-nav ul li.has-dropdown .dropdown li:last-child {
  margin-bottom: 0;
}
.fh5co-nav ul li.has-dropdown .dropdown li a {
  padding: 2px 0;
  display: block;
  color: #999999;
  line-height: 1.2;
  text-transform: none;
  font-size: 13px;
  letter-spacing: 0;
}
.fh5co-nav ul li.has-dropdown .dropdown li a:hover {
  color: #fff;
}
.fh5co-nav ul li.has-dropdown:hover a,
.fh5co-nav ul li.has-dropdown:focus a {
  color: #000;
}
.fh5co-nav ul li.btn-cta a {
  padding: 30px 0px !important;
  color: #fff;
}
.fh5co-nav ul li.btn-cta a span {
  background: #f95959;
  padding: 4px 10px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  display: inline;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
}
.fh5co-nav ul li.btn-cta a:hover span {
  -webkit-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
}
.fh5co-nav ul li.active > a {
  color: #f95959 !important;
  position: relative;
}
.fh5co-nav ul li.active > a:after {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.fh5co-bg {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

.fh5co-video {
  overflow: hidden;
}
@media screen and (max-width: 992px) {
  .fh5co-video {
    height: 450px;
  }
}
.fh5co-video a {
  z-index: 1001;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -45px;
  margin-left: -45px;
  width: 90px;
  height: 90px;
  display: table;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -ms-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -o-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.fh5co-video a i {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-size: 40px;
}
.fh5co-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.fh5co-video:hover .overlay {
  background: rgba(0, 0, 0, 0.7);
}
.fh5co-video:hover a {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

#fh5co-contact,
#fh5co-work,
#fh5co-footer {
  padding: 1em 0;
  clear: both;
}
@media screen and (max-width: 768px) {
  #fh5co-contact,
  #fh5co-work,
  #fh5co-footer {
    padding: 3em 1em;
  }
}

#fh5co-intro {
  padding: 1em 0 1em 0;
}
#fh5co-intro h2 {
  font-size: 38px;
  line-height: 44px;
  color: #000000;
}

@media screen and (max-width: 768px) {
  #fh5co-intro h2 {
    font-size: 29px;
    line-height: 38px;
  }
}
#fh5co-intro h2 span {
  font-family: "Shippori Antique" !important;
  font-size: 16px;
}
#fh5co-intro h2 span em {
  color: rgba(0, 0, 0, 0.3);
  margin-right: 5px;
}

.portfolio-grid {
  width: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}
.portfolio-grid:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.portfolio-grid img {
  position: relative;
  max-width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.portfolio-grid .desc {
  z-index: 9;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  padding: 30px;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
}
@media screen and (max-width: 768px) {
  .portfolio-grid .desc {
    opacity: 1;
  }
}
.portfolio-grid .desc h3 {
  color: #fff;
  margin-bottom: 10px;
  font-weight: 400;
}
.portfolio-grid .desc span {
  color: rgba(255, 255, 255, 0.8);
}
.portfolio-grid:hover:after {
  opacity: 1;
}
.portfolio-grid:hover .desc {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.portfolio-grid:hover img {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}

.detail h2 {
  margin-bottom: 10px;
}
.detail span {
  display: block;
  color: #999999;
  margin-bottom: 30px;
}
.detail p {
  font-size: 18px;
}

.form-wrap {
  padding: 3em;
  background: rgba(255, 255, 255, 0.8);
}

.contact-info {
  margin-bottom: 4em;
  padding: 0;
}
.contact-info li {
  list-style: none;
  margin: 0 0 20px 0;
  position: relative;
  padding-left: 40px;
  color: #000;
}
.contact-info li i {
  position: absolute;
  top: 0.3em;
  left: 0;
  font-size: 18px;
  color: #f95959;
}
.contact-info li a {
  color: #000;
}

.form-control {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  border: none;
  font-size: 16px !important;
  font-weight: 400;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
}
.form-control:focus,
.form-control:active {
  box-shadow: none;
  border: 2px solid rgba(0, 0, 0, 0.8);
}

input[type="text"] {
  height: 50px;
}

.form-group {
  margin-bottom: 30px;
}
.form-group .btn-modify {
  font-family: "Shippori Antique";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 13px;
  padding: 10px 15px;
}

.fh5co-social-icons {
  margin: 0;
  padding: 0;
}
.fh5co-social-icons li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  display: inline;
}
.fh5co-social-icons li a {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  display: inline;
  color: #7a5001;
  padding-left: 10px;
  padding-right: 10px;
}
.fh5co-social-icons li a i {
  font-size: 30px;
}

.fh5co-heading {
  margin-bottom: 5em;
}
.fh5co-heading.fh5co-heading-sm {
  margin-bottom: 2em;
}
.fh5co-heading h2 {
  font-size: 13px;
  margin-bottom: 20px;
  line-height: 1.5;
  font-weight: 700;
  font-family: "Shippori Antique";
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000;
}
.fh5co-heading span {
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
}

#fh5co-footer {
  margin-top: 20px !important;
}
#fh5co-footer .fh5co-footer-links {
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 768px) {
  #fh5co-footer .fh5co-footer-links {
    margin-bottom: 30px;
  }
}
#fh5co-footer .fh5co-footer-links li {
  padding: 0;
  margin: 0 0 10px 0;
  list-style: none;
  display: block;
}
#fh5co-footer .fh5co-footer-links li a {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
}
#fh5co-footer .fh5co-footer-links li a:hover {
  text-decoration: underline;
}
#fh5co-footer h4 {
  margin-bottom: 30px;
  font-size: 24px;
}
#fh5co-footer .copyright {
  display: block;
  margin-top: 3em;
}
#fh5co-footer .copyright .block {
  display: block;
}

#fh5co-offcanvas {
  position: absolute;
  z-index: 1901;
  width: 270px;
  background: black;
  top: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 75px 40px 40px 40px;
  overflow-y: auto;
  display: none;
  -moz-transform: translateX(270px);
  -webkit-transform: translateX(270px);
  -ms-transform: translateX(270px);
  -o-transform: translateX(270px);
  transform: translateX(270px);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
@media screen and (max-width: 768px) {
  #fh5co-offcanvas {
    display: block;
  }
}
.offcanvas #fh5co-offcanvas {
  -moz-transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
}
#fh5co-offcanvas a {
  color: rgba(255, 255, 255, 0.5);
}
#fh5co-offcanvas a:hover {
  color: rgba(255, 255, 255, 0.8);
}
#fh5co-offcanvas ul {
  padding: 0;
  margin: 0;
}
#fh5co-offcanvas ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 18px;
}
#fh5co-offcanvas ul li > ul {
  padding-left: 20px;
  display: none;
}
#fh5co-offcanvas ul li.offcanvas-has-dropdown > a {
  display: block;
  position: relative;
}
#fh5co-offcanvas ul li.offcanvas-has-dropdown > a:after {
  position: absolute;
  right: 0px;
  font-family: "icomoon";
  /* speak: none; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e0c5";
  font-size: 20px;
  color: rgba(255, 255, 255, 0.2);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
#fh5co-offcanvas ul li.offcanvas-has-dropdown.active a:after {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
#fh5co-offcanvas ul li.active > a {
  color: #f95959;
}

.gototop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.gototop.active {
  opacity: 1;
  visibility: visible;
}
.gototop a {
  width: 50px;
  height: 50px;
  display: table;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.gototop a i {
  height: 50px;
  display: table-cell;
  vertical-align: middle;
}
.gototop a:hover,
.gototop a:active,
.gototop a:focus {
  text-decoration: none;
  outline: none;
}

.fh5co-nav-toggle {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-decoration: none;
  top: 25px !important;
}
.fh5co-nav-toggle.active i::before,
.fh5co-nav-toggle.active i::after {
  background: #444;
}
.fh5co-nav-toggle:hover,
.fh5co-nav-toggle:focus,
.fh5co-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}
.fh5co-nav-toggle i {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 2px;
  color: #252525;
  font: bold 14px/0.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #252525;
  transition: all 0.2s ease-out;
}
.fh5co-nav-toggle i::before,
.fh5co-nav-toggle i::after {
  content: "";
  width: 25px;
  height: 2px;
  background: #252525;
  position: absolute;
  left: 0;
  transition: all 0.2s ease-out;
}
.fh5co-nav-toggle.fh5co-nav-white > i {
  color: #fff;
  background: #000;
}
.fh5co-nav-toggle.fh5co-nav-white > i::before,
.fh5co-nav-toggle.fh5co-nav-white > i::after {
  background: #000;
}

.fh5co-nav-toggle i::before {
  top: -7px;
}

.fh5co-nav-toggle i::after {
  bottom: -7px;
}

.fh5co-nav-toggle:hover i::before {
  top: -10px;
}

.fh5co-nav-toggle:hover i::after {
  bottom: -10px;
}

.fh5co-nav-toggle.active i {
  background: transparent;
}

.fh5co-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  background: #fff;
}

.fh5co-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
  background: #fff;
}

.fh5co-nav-toggle {
  position: absolute;
  right: 0px;
  top: 65px;
  z-index: 21;
  padding: 6px 0 0 0;
  display: block;
  margin: 0 auto;
  display: none;
  height: 44px;
  width: 44px;
  z-index: 2001;
  border-bottom: none !important;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-toggle {
    display: block;
  }
}

.btn {
  margin-right: 4px;
  margin-bottom: 4px;
  font-family: "Shippori Antique";
  font-size: 16px;
  font-weight: 400;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  -ms-border-radius: 1px;
  border-radius: 1px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 8px 20px;
}
.btn.btn-md {
  padding: 8px 20px !important;
}
.btn.btn-lg {
  padding: 18px 36px !important;
}
.btn:hover,
.btn:active,
.btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary {
  background: #f95959;
  color: #fff;
  border: 2px solid #f95959;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background: #fa7272 !important;
  border-color: #fa7272 !important;
}
.btn-primary.btn-outline {
  background: transparent;
  color: #f95959;
  border: 2px solid #f95959;
}
.btn-primary.btn-outline:hover,
.btn-primary.btn-outline:focus,
.btn-primary.btn-outline:active {
  background: #f95959;
  color: #fff;
}

.btn-success {
  background: #5cb85c;
  color: #fff;
  border: 2px solid #5cb85c;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active {
  background: #4cae4c !important;
  border-color: #4cae4c !important;
}
.btn-success.btn-outline {
  background: transparent;
  color: #5cb85c;
  border: 2px solid #5cb85c;
}
.btn-success.btn-outline:hover,
.btn-success.btn-outline:focus,
.btn-success.btn-outline:active {
  background: #5cb85c;
  color: #fff;
}

.btn-info {
  background: #5bc0de;
  color: #fff;
  border: 2px solid #5bc0de;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active {
  background: #46b8da !important;
  border-color: #46b8da !important;
}
.btn-info.btn-outline {
  background: transparent;
  color: #5bc0de;
  border: 2px solid #5bc0de;
}
.btn-info.btn-outline:hover,
.btn-info.btn-outline:focus,
.btn-info.btn-outline:active {
  background: #5bc0de;
  color: #fff;
}

.btn-warning {
  background: #f0ad4e;
  color: #fff;
  border: 2px solid #f0ad4e;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active {
  background: #eea236 !important;
  border-color: #eea236 !important;
}
.btn-warning.btn-outline {
  background: transparent;
  color: #f0ad4e;
  border: 2px solid #f0ad4e;
}
.btn-warning.btn-outline:hover,
.btn-warning.btn-outline:focus,
.btn-warning.btn-outline:active {
  background: #f0ad4e;
  color: #fff;
}

.btn-danger {
  background: #d9534f;
  color: #fff;
  border: 2px solid #d9534f;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active {
  background: #d43f3a !important;
  border-color: #d43f3a !important;
}
.btn-danger.btn-outline {
  background: transparent;
  color: #d9534f;
  border: 2px solid #d9534f;
}
.btn-danger.btn-outline:hover,
.btn-danger.btn-outline:focus,
.btn-danger.btn-outline:active {
  background: #d9534f;
  color: #fff;
}

.btn-outline {
  background: none;
  border: 2px solid gray;
  font-size: 16px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
  box-shadow: none;
}

.btn.with-arrow {
  position: relative;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.btn.with-arrow i {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -8px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.btn.with-arrow:hover {
  padding-right: 50px;
}
.btn.with-arrow:hover i {
  color: #fff;
  right: 18px;
  visibility: visible;
  opacity: 1;
}

.form-control {
  box-shadow: none;
  background: transparent;
  border: 2px solid rgba(0, 0, 0, 0.1);
  height: 54px;
  font-size: 18px;
  font-weight: 300;
}
.form-control:active,
.form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #f95959;
}

.row-pb-md {
  padding-bottom: 4em !important;
}

.row-pb-sm {
  padding-bottom: 2em !important;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.padding-right {
  padding: 0 !important;
  padding-right: 10px !important;
}
@media screen and (max-width: 768px) {
  .padding-right {
    padding-right: 0 !important;
  }
}

.padding-left {
  padding: 0 !important;
  padding-left: 10px !important;
}
@media screen and (max-width: 768px) {
  .padding-left {
    padding-left: 0 !important;
  }
}

.fh5co-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(./assets/loader.gif) center no-repeat #fff;
}

.js .animate-box {
  opacity: 1;
}

.image-item {
  margin-bottom: 20px;
  float: left;
  width: 100%;
}

#sticky_item {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
#sticky_item.is_stuck {
  margin-top: 60px;
}

@media screen and (max-width: 992px) {
  .sticky-parent {
    clear: both;
    height: inherit !important;
    float: left;
    padding-top: 30px;
  }
}

/*# sourceMappingURL=style.css.map */

/*My own styling*/
.logo {
  display: flex;
  flex-direction: column;
  color: #000 !important;
  width: 400px;
}

.high-park {
  font-family:"Shippori Antique";
  font-weight: 600;
  font-size: 3rem;
  letter-spacing: 0.5rem;
  line-height: 0.667;
  color: #000000;
}

.architecture-interior-architecture {
  margin-top: 5px;
  overflow-wrap: break-word;
  font-family: "Shippori Antique";
  font-weight: 600;
  font-size: 1rem;
  line-height: 2;
  color: #000000;
}

/*Personal Styles*/
#thehero {
  opacity: 1;
  min-height: 500px;
  margin-top: 15rem;
  margin-bottom: 3rem;
}
.container-fluid {
  position: relative; 
  height: 70vh;
}
.slider-text {
  position: absolute;
  text-align: end;
  bottom: 0; 
  right: 0;
  padding: 20px 10px 0px 10px;
  color: white; 
  background-color: #cd332eb2;
  line-height: 0;
  font-size: 10px;
}
.slide-caption{
  line-height: 0;
  font-size: 17px;
  font-family: "Shippori Antique";
  font-weight: 700;
}
.about-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 0rem;
}
.aboutitle{
  text-align: center;
  font-size: 30px;
}
.about-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.about-text p {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 1.6;
}

.about-text ul {
  list-style-type: none;
  padding: 0;
}

.about-text li {
  margin-bottom: 1rem;
}

.about-text strong {
  color: #000;
  display: block;
  margin-bottom: 0.5rem;
}
.about-image{
  display: flex;
  justify-content: center;
  object-fit: contain;

}
.about-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .about-content {
    flex-direction: row;
  }

  .about-text {
    width: 50%;
  }

  .about-image {
    width: 50%;
  }

  .about-image img {
    max-width: 90%;
  }
}